import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Components
import Layout from '~components/layout'
import RequestDemoForm from '../components/request-demo-form';

// Images
import appDevelopment from '~images/app_development.svg'
import bgElement from '~images/bg-elements-7.svg'
import wave from '~images/wave_4_bottom_teal.svg'
import wave2 from '~images/wave-1-bottom-sm.svg'

const PricingContact = (props) => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');
  const [clientAmt, setClientAmt] = useState(1000);
  const [compState, setCompState] = useState('');
  const [sending, setSending] = useState('false');

  const sendData = {
    subject: 'Demo Request From: ' + name,
    name: name,
    email: email,
    phoneNumber: phoneNumber,
    companyName: company,
    message: message,
    clientAmt: clientAmt,
    compState: compState,
    dest: 'sales'

  }

  const compStates = [
    {
      label: "Alabama",
      value: "AL"
    },
    {
      label: "Alaska",
      value: "AK"
    },
    {
      label: "Arizona",
      value: "AZ"
    },
    {
      label: "Arkansas",
      value: "AR"
    },
    {
      label: "California",
      value: "CA"
    },
    {
      label: "Colorado",
      value: "CO"
    },
    {
      label: "Connecticut",
      value: "CT"
    },
    {
      label: "Delaware",
      value: "DE"
    },
    {
      label: "District Of Columbia",
      value: "DC"
    },
    {
      label: "Florida",
      value: "FL"
    },
    {
      label: "Georgia",
      value: "GA"
    },
    {
      label: "Guam",
      value: "GU"
    },
    {
      label: "Hawaii",
      value: "HI"
    },
    {
      label: "Idaho",
      value: "ID"
    },
    {
      label: "Illinois",
      value: "IL"
    },
    {
      label: "Indiana",
      value: "IN"
    },
    {
      label: "Iowa",
      value: "IA"
    },
    {
      label: "Kansas",
      value: "KS"
    },
    {
      label: "Kentucky",
      value: "KY"
    },
    {
      label: "Louisiana",
      value: "LA"
    },
    {
      label: "Maine",
      value: "ME"
    },
    {
      label: "Maryland",
      value: "MD"
    },
    {
      label: "Massachusetts",
      value: "MA"
    },
    {
      label: "Michigan",
      value: "MI"
    },
    {
      label: "Minnesota",
      value: "MN"
    },
    {
      label: "Mississippi",
      value: "MS"
    },
    {
      label: "Missouri",
      value: "MO"
    },
    {
      label: "Montana",
      value: "MT"
    },
    {
      label: "Nebraska",
      value: "NE"
    },
    {
      label: "Nevada",
      value: "NV"
    },
    {
      label: "New Hampshire",
      value: "NH"
    },
    {
      label: "New Jersey",
      value: "NJ"
    },
    {
      label: "New Mexico",
      value: "NM"
    },
    {
      label: "New York",
      value: "NY"
    },
    {
      label: "North Carolina",
      value: "NC"
    },
    {
      label: "North Dakota",
      value: "ND"
    },
    {
      label: "Ohio",
      value: "OH"
    },
    {
      label: "Oklahoma",
      value: "OK"
    },
    {
      label: "Oregon",
      value: "OR"
    },
    {
      label: "Palau",
      value: "PW"
    },
    {
      label: "Pennsylvania",
      value: "PA"
    },
    {
      label: "Puerto Rico",
      value: "PR"
    },
    {
      label: "Rhode Island",
      value: "RI"
    },
    {
      label: "South Carolina",
      value: "SC"
    },
    {
      label: "South Dakota",
      value: "SD"
    },
    {
      label: "Tennessee",
      value: "TN"
    },
    {
      label: "Texas",
      value: "TX"
    },
    {
      label: "Utah",
      value: "UT"
    },
    {
      label: "Vermont",
      value: "VT"
    },
    {
      label: "Virgin Islands",
      value: "VI"
    },
    {
      label: "Virginia",
      value: "VA"
    },
    {
      label: "Washington",
      value: "WA"
    },
    {
      label: "West Virginia",
      value: "WV"
    },
    {
      label: "Wisconsin",
      value: "WI"
    },
    {
      label: "Wyoming",
      value: "WY"
    }
  ];

  const headers = {
    'Content-Type': 'application/json'
  }

  const DemoRequestButton = event => {
    if (sending === 'false') {
      return <button type="submit" className="btn btn-secondary transition-3d-hover">Let's Start Working Together</button>;
    } else if (sending === 'true') {
      return <button type="button" className="btn btn-success disabled">Sending Demo Request</button>;
    } else if (sending === 'sent') {
      return <button type="button" className="btn btn-info disabled">Your Request Has Been Sent</button>;
    }
  }

  const handleSubmit = event => {
    event.preventDefault();
    setSending('true');

    axios.post(`https://84fdl5xpqd.execute-api.us-west-2.amazonaws.com/dev`, sendData, { headers: headers })
      .then(res => {
        console.log(res);
        setSending('sent');
        setTimeout(() => { setSending('false'); }, 3000)
      })
  }

  return (
    <Layout>
      <main id="content" role="main">
        {/* Hire Us Title Section */}
        <div id="SVGheroBgElements" className="position-relative gradient-half-qvr-v3 z-index-2 overflow-hidden">
          <div className="container space-top-2 space-bottom-3 space-top-md-5 space-bottom-lg-5">
            <div className="w-md-80 w-lg-50 text-center mx-md-auto">
              <h1 className="text-white">Contact Us<span className="font-weight-semi-bold"> About Your</span> Company</h1>
              <p className="lead text-white-70">We help brands and platforms turn big ideas into beautiful digital products and experiences.</p>
            </div>
          </div>

          {/* SVG Background Shapes */}
          <div className="w-100 w-sm-75 w-md-50 content-centered-y z-index-n1 ml-9">
            <figure className="ie-bg-elements-2">
              <img className="js-svg-injector" src={bgElement} aria-hidden="true" alt=""
                data-parent="#SVGheroBgElements" />
            </figure>
          </div>
          {/* End SVG Background Shapes */}

          {/* SVG Bottom Shape */}
          <figure className="position-absolute right-0 bottom-0 left-0">
            <img className="js-svg-injector" src={wave} aria-hidden="true" alt=""
              data-parent="#SVGheroBgElements" />
          </figure>
          {/* End SVG Bottom Shape */}
        </div>
        {/* End Hire Us Title Section */}

        {/* Start Demo Form */}
        <div className="container space-bottom-2">
          {/* Hire Us Form */}
          <form className="js-validate w-lg-50 mx-auto" onSubmit={handleSubmit}>

            <div className="row">
              {/* Input */}
              <div className="js-form-message mb-6 col-md-6">
                <label className="form-label">
                  Your name
                  <span className="text-danger">*</span>
                </label>
                <input type="text" className="form-control" name="firstName" placeholder="Your Name" aria-label="Your Name" required
                  data-msg="Please enter your frist name."
                  data-error-className="u-has-error"
                  data-success-className="u-has-success"
                  onChange={e => setName(e.target.value)} />
              </div>
              {/* End Input */}
              {/* Input */}
              <div className="js-form-message mb-6 col-md-6">
                <label className="form-label">
                  Your email address
                  <span className="text-danger">*</span>
                </label>

                <input type="email" className="form-control" name="emailAddress" placeholder="yourEmail@gmail.com" aria-label="yourEmail@gmail.com" required
                  data-msg="Please enter a valid email address."
                  data-error-className="u-has-error"
                  data-success-className="u-has-success"
                  onChange={e => setEmail(e.target.value)} />
              </div>
              {/* End Input */}
            </div>

            <div className="row">
              {/* Input */}
              <div className="js-focus-state mb-6 col-md-6">
                <label className="form-label">Your company name</label>
                <input type="text" className="form-control" placeholder="Company Inc." aria-label="Company Inc."
                  onChange={e => setCompany(e.target.value)} />
              </div>
              {/* End Input */}
              {/* Input */}
              <div className="js-focus-state mb-6 col-md-6">
                <label className="form-label">Your State</label>
                <select className="form-control" name="compState" onChange={e => setCompState(e.target.value)}>
                  <option disabled selected value>Select Your State</option>
                  {compStates.map((e, key) => {
                    return <option key={e.value} value={e.value}>{e.label}</option>;
                  })}
                </select>
              </div>
              {/* End Input */}

            </div>

            <div className="row">
              {/* Input */}
              <div className="js-form-message mb-6 col-md-6">
                <label className="form-label">
                  Approximate Amount of Clients
                  <span className="text-danger">*</span>
                </label>

                <input type="number" defaultValue={clientAmt} className="form-control" name="clientAmt" placeholder="Any Number" aria-label="Any Number" required
                  data-msg="Please enter how many clients will use this."
                  data-error-className="u-has-error"
                  data-success-className="u-has-success"
                  onChange={e => setClientAmt(e.target.value)} />
              </div>
              {/* End Input */}
              {/* Input */}
              <div className="js-focus-state mb-6 col-md-6">
                <label className="form-label">Your Phone Number</label>
                <input type="text" className="form-control" placeholder="801 555-5555" aria-label="801 555-5555"
                  onChange={e => setPhoneNumber(e.target.value)} />
              </div>
              {/* End Input */}
            </div>


            {/* Budget Custom Select */}
            {/* <div className="mb-6">
              <label className="form-label">
                Tell us about your budget
                <span className="text-danger">*</span>
              </label>

              <div className="js-focus-state">
                <select className="form-control custom-select" required
                  data-msg="Please select your budget."
                  data-error-className="u-has-error"
                  data-success-className="u-has-success">
                  <option value="" selected>$20,000 to $50,000</option>
                  <option value="budget1">$20,000 to $50,000</option>
                  <option value="budget2">$100,000 to $200,000</option>
                  <option value="budget3">$200,000+</option>
                </select>
              </div>
            </div> */}
            {/* End Budget Custom Select */}

            {/* Input */}
            <div className="js-form-message mb-6">
              <label className="form-label">
                How Can we Help You
                <span className="text-danger">*</span>
              </label>

              <textarea className="form-control" rows="4" name="answer" placeholder="Write us a short message about your company with 1000+ clients" aria-label="Write us a short message about your company with 1000+ clients" required
                data-msg="Please enter a reason."
                data-error-className="u-has-error"
                data-success-className="u-has-success"
                onChange={e => setMessage(e.target.value)}></textarea>
            </div>
            {/* End Input */}

            <div className="text-center">
              <div className="mb-2">
                <DemoRequestButton />
              </div>
              <p className="small">We'll get back to you immediately.</p>
            </div>
          </form>
          {/* End Hire Us Form */}
        </div>
        {/* End Demo Form */}

        {/* Features Section */}
        <div id="SVGelements" className="svg-preloader">
          <div className="gradient-half-primary-v3">
            <div className="container space-bottom-2">
              <div className="w-60 mx-auto">
                {/* SVG Icon */}
                <figure className="ie-app-development">
                  <img src={appDevelopment} alt="SVG Illustration" style={{ width: `100%` }} />
                </figure>
                {/* End SVG Icon */}
              </div>
            </div>
          </div>

          {/* SVG Background */}
          <figure className="position-absolute right-0 bottom-0 left-0">
            <img style={{ width: '100%', height: '140px' }} src={wave2} alt="" aria-hidden="true" />
          </figure>
          {/* End SVG Background */}
        </div>
        {/* End Features Section */}
      </main>
      {/* ========== END MAIN ========== */}
    </Layout>
  )
}

export default PricingContact
